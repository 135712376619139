import * as amplitude from '@amplitude/analytics-browser';

export const analyticsInitialize = ({ amplitudeApiKey }) => {
  if (amplitudeApiKey) {
    amplitude.init(amplitudeApiKey, undefined, {
      autocapture: {
        sessions: true,
        pageViews: true,
        formInteractions: true,
        fileDownloads: true,
      },
    });
  }
};

export const identify = (userId, customParams) => {
  if (amplitude) {
    if (userId) {
      amplitude.setUserId(userId);

      if (customParams) {
        const identifyUserParams = new amplitude.Identify();

        for (const paramKey of Object.keys(customParams)) {
          identifyUserParams.set(paramKey, customParams[paramKey]);
        }

        amplitude.identify(identifyUserParams, {
          user_id: userId,
        });
      }
    }

    if (userId === null) {
      amplitude.reset();
    }
  }
};

export const trackEvent = (eventName, eventParams) => {
  if (amplitude) {
    amplitude.logEvent(eventName, eventParams);
  }
};
